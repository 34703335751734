export function calculateStatusGizi(bmi) {
  /*
    Angka Indeks Massa Tubuh (IMT) dari hasil perhitungan no.6, status gizi dikategorikan sebagai berikut:

    Jika IMT < 18.50 -->  Berat Badan Kurang / below_normal
    Jika IMT 18.5 - 22.99 --> Normal / normal
    Jika IMT 23.0 - 25.0 --> Preobesitas / pre_obesity
    Jika IMT 25.0 - 29.99 --> Obesitas 1 / obesity
    Jika IMT ≥ 30 --> Obesitas 2 / obesity
  */
  if (bmi < 18.5) {
    return 'below_normal'
  } else if (bmi >= 18.5 && bmi <= 22.99) {
    return 'normal'
  } else if (bmi >= 23 && bmi <= 25) {
    return 'pre_obesity'
  } else if (bmi >= 25 && bmi <= 29.99) {
    return 'obesity'
  } else if (bmi >= 30) {
    return 'obesity'
  }
}
