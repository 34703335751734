var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": "590px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.fullName.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Nama Lengkap ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "placeholder": "Masukkan Nama Lengkap",
      "height": "62px"
    },
    model: {
      value: _vm.fullName,
      callback: function callback($$v) {
        _vm.fullName = $$v;
      },
      expression: "fullName"
    }
  })], 1), !_vm.$v.fullName.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Nama lengkap harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.age.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Umur (tahun) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "id": "Age",
      "type": "number",
      "placeholder": "Masukkan Umur (tahun)",
      "height": "62px"
    },
    model: {
      value: _vm.age,
      callback: function callback($$v) {
        _vm.age = _vm._n($$v);
      },
      expression: "age"
    }
  })], 1), !_vm.$v.age.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Umur harus diisi ")]) : _vm._e(), !_vm.$v.age.numeric ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Umur harus berupa angka ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.gender.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Jenis Kelamin ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Jenis Kelamin",
      "height": "62px"
    },
    model: {
      value: _vm.gender,
      callback: function callback($$v) {
        _vm.gender = $$v;
      },
      expression: "gender"
    }
  }, [_c('option', {
    attrs: {
      "value": "male"
    }
  }, [_vm._v(" Laki-laki ")]), _c('option', {
    attrs: {
      "value": "female"
    }
  }, [_vm._v(" Perempuan ")])])], 1), !_vm.$v.gender.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Jenis Kelamin harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.physicalActivity.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" % Aktivitas Fisik ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Pilih % Aktivitas Fisik",
      "height": "62px"
    },
    model: {
      value: _vm.physicalActivity,
      callback: function callback($$v) {
        _vm.physicalActivity = $$v;
      },
      expression: "physicalActivity"
    }
  }, [_c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(" 0% ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10% ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" 20% ")]), _c('option', {
    attrs: {
      "value": "30"
    }
  }, [_vm._v(" 30% ")]), _c('option', {
    attrs: {
      "value": "40"
    }
  }, [_vm._v(" 40% ")]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v(" 50% ")]), _c('option', {
    attrs: {
      "value": "60"
    }
  }, [_vm._v(" 60% ")]), _c('option', {
    attrs: {
      "value": "70"
    }
  }, [_vm._v(" 70% ")]), _c('option', {
    attrs: {
      "value": "80"
    }
  }, [_vm._v(" 80% ")]), _c('option', {
    attrs: {
      "value": "90"
    }
  }, [_vm._v(" 90% ")])])], 1), !_vm.$v.physicalActivity.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Aktivitas fisik harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.weight.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Berat Badan (kg) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Berat Badan",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.weight,
      callback: function callback($$v) {
        _vm.weight = _vm._n($$v);
      },
      expression: "weight"
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px",
      "margin-right": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "black.900",
      "font-size": "18px"
    }
  }, [_vm._v(" kg ")])], 1)], 1), !_vm.$v.weight.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Berat badan harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.height.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tinggi Badan (cm) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Tinggi Badan",
      "height": "62px",
      "padding-right": "60px"
    },
    model: {
      value: _vm.height,
      callback: function callback($$v) {
        _vm.height = _vm._n($$v);
      },
      expression: "height"
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px",
      "margin-right": "10px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "black.900",
      "font-size": "18px"
    }
  }, [_vm._v(" cm ")])], 1)], 1), !_vm.$v.height.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Tinggi badan harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.bodyMassIndex.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Indeks Massa Tubuh (IMT) (kg/m2) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Indeks Massa Tubuh (IMT) (kg/m2)",
      "height": "62px",
      "padding-right": "90px"
    },
    model: {
      value: _vm.bodyMassIndex,
      callback: function callback($$v) {
        _vm.bodyMassIndex = _vm._n($$v);
      },
      expression: "bodyMassIndex"
    }
  }), _c('c-input-right-element', {
    attrs: {
      "width": "4.5rem",
      "mt": "10px",
      "margin-right": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "black.900",
      "font-size": "18px"
    }
  }, [_vm._v(" (kg/m2) ")])], 1)], 1), !_vm.$v.bodyMassIndex.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Indeks massa tubuh harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.nutritionStatus.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Status Gizi ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Masukkan Status Gizi",
      "height": "62px"
    },
    model: {
      value: _vm.nutritionStatus,
      callback: function callback($$v) {
        _vm.nutritionStatus = $$v;
      },
      expression: "nutritionStatus"
    }
  }, [_c('option', {
    attrs: {
      "value": "below_normal"
    }
  }, [_vm._v(" Berat Badan Kurang ")]), _c('option', {
    attrs: {
      "value": "normal"
    }
  }, [_vm._v(" Normal ")]), _c('option', {
    attrs: {
      "value": "pre_obesity"
    }
  }, [_vm._v(" Preobesitas ")]), _c('option', {
    attrs: {
      "value": "obesity"
    }
  }, [_vm._v(" Obesitas ")])])], 1), !_vm.$v.nutritionStatus.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Status gizi harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.idealWeightMinimum.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Minimum Berat Badan Ideal ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Minimum Berat Badan Ideal",
      "height": "62px"
    },
    model: {
      value: _vm.idealWeightMinimum,
      callback: function callback($$v) {
        _vm.idealWeightMinimum = _vm._n($$v);
      },
      expression: "idealWeightMinimum"
    }
  })], 1), !_vm.$v.idealWeightMinimum.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Minimum berat badan ideal harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.idealWeightMaximum.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Maximum Berat Badan Ideal ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Maximum Berat Badan Ideal",
      "height": "62px"
    },
    model: {
      value: _vm.idealWeightMaximum,
      callback: function callback($$v) {
        _vm.idealWeightMaximum = $$v;
      },
      expression: "idealWeightMaximum"
    }
  })], 1), !_vm.$v.idealWeightMaximum.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Maximum berat badan ideal harus diisi ")]) : _vm._e()], 1), _vm.gender === 'female' ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.maternalCondition.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kondisi Keibuan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Kondisi Keibuan",
      "height": "62px"
    },
    model: {
      value: _vm.maternalCondition,
      callback: function callback($$v) {
        _vm.maternalCondition = $$v;
      },
      expression: "maternalCondition"
    }
  }, [_c('option', {
    attrs: {
      "value": "0"
    }
  }, [_vm._v(" Tidak ada / Hamil Trimester 1 ")]), _c('option', {
    attrs: {
      "value": "340"
    }
  }, [_vm._v(" Hamil Trimester 2 ")]), _c('option', {
    attrs: {
      "value": "452"
    }
  }, [_vm._v(" Hamil Trimester 3 ")]), _c('option', {
    attrs: {
      "value": "330"
    }
  }, [_vm._v(" Menyusui Bayi 0-6 Bulan ")]), _c('option', {
    attrs: {
      "value": "400"
    }
  }, [_vm._v(" Menyusui Bayi 7-12 Bulan ")])])], 1), !_vm.$v.maternalCondition.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Kondisi keibuan harus diisi ")]) : _vm._e()], 1) : _vm._e(), _vm.gender === 'female' ? _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.extraMaternalCalories.$invalid,
      "mb": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555",
      "font-weight": "400"
    }
  }, [_vm._v(" Tambahan kalori kondisi keibuan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "name": "extraMaternalCalories",
      "placeholder": "Masukkan Tambahan kalori kondisi keibuan",
      "height": "62px"
    },
    model: {
      value: _vm.extraMaternalCalories,
      callback: function callback($$v) {
        _vm.extraMaternalCalories = $$v;
      },
      expression: "extraMaternalCalories"
    }
  })], 1), !_vm.$v.extraMaternalCalories.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Tambahan kalori kondisi keibuan harus diisi ")]) : _vm._e(), !_vm.$v.extraMaternalCalories.numeric ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Tambahan kalori kondisi keibuan harus berupa angka ")]) : _vm._e()], 1) : _vm._e(), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.mealPlanGoal.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Tujuan Meal Plan ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mealPlanGoal,
      expression: "mealPlanGoal"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "mealPlanGoal",
      "rows": "3",
      "placeholder": "Masukkan Tujuan Meal Plan",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.mealPlanGoal
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.mealPlanGoal = $event.target.value;
      }
    }
  }), _vm._v(" "), !_vm.$v.mealPlanGoal.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Tujuan Meal Plan harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.maintainedThings.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Hal yang Perlu Dipertahankan ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.maintainedThings,
      expression: "maintainedThings"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "maintainedThings",
      "rows": "3",
      "placeholder": "Masukkan Hal yang Perlu Dipertahankan",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.maintainedThings
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.maintainedThings = $event.target.value;
      }
    }
  }), _vm._v(" "), !_vm.$v.maintainedThings.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Hal yang Perlu Dipertahankan harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.fixedThings.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Hal yang Perlu Diperbaiki ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fixedThings,
      expression: "fixedThings"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "fixedThings",
      "rows": "3",
      "placeholder": "Masukkan Hal yang Perlu Diperbaiki",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.fixedThings
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.fixedThings = $event.target.value;
      }
    }
  }), _vm._v(" "), !_vm.$v.fixedThings.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Hal yang Perlu Diperbaiki harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.basalEnergyNeeds.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Kebutuhan Energi Bassal (kkal) ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-input', {
    attrs: {
      "type": "number",
      "placeholder": "Masukkan Kebutuhan Energi Bassal (kkal)",
      "height": "62px"
    },
    model: {
      value: _vm.basalEnergyNeeds,
      callback: function callback($$v) {
        _vm.basalEnergyNeeds = _vm._n($$v);
      },
      expression: "basalEnergyNeeds"
    }
  })], 1), !_vm.$v.basalEnergyNeeds.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Kebutuhan Energi Bassal harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.calorieDeficit.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Defisit kalori yang diberikan ")]), _c('c-input-group', {
    attrs: {
      "size": "md"
    }
  }, [_c('c-select', {
    attrs: {
      "placeholder": "Pilih Defisit kalori yang diberikan",
      "height": "62px"
    },
    model: {
      value: _vm.calorieDeficit,
      callback: function callback($$v) {
        _vm.calorieDeficit = $$v;
      },
      expression: "calorieDeficit"
    }
  }, [_c('option', {
    attrs: {
      "value": "250"
    }
  }, [_vm._v(" 250 ")]), _c('option', {
    attrs: {
      "value": "350"
    }
  }, [_vm._v(" 350 ")]), _c('option', {
    attrs: {
      "value": "500"
    }
  }, [_vm._v(" 500 ")]), _c('option', {
    attrs: {
      "value": "650"
    }
  }, [_vm._v(" 650 ")]), _c('option', {
    attrs: {
      "value": "750"
    }
  }, [_vm._v(" 750 ")])])], 1), !_vm.$v.calorieDeficit.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Defisit kalori yang diberikan harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.target.$invalid,
      "margin-bottom": "30px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Target ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.target,
      expression: "target"
    }, {
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "id": "target",
      "rows": "3",
      "placeholder": "Masukkan Target",
      "d": "block",
      "border": "1px solid #E2E8F0",
      "border-radius": "md",
      "minHeight": "150px",
      "type": "text",
      "lineHeight": "20px",
      "p": "10px",
      "w": "100%"
    },
    domProps: {
      "value": _vm.target
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.target = $event.target.value;
      }
    }
  }), _vm._v(" "), !_vm.$v.target.required ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500",
      "font-size": "12px"
    }
  }, [_vm._v(" Target harus diisi ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "margin-bottom": "30px",
      "display": "flex"
    }
  }, [_c('c-button', {
    attrs: {
      "background-color": "superLightGray.900",
      "px": "26px",
      "font-size": "18px",
      "font-weight": "500",
      "py": "26px",
      "border-radius": "58px",
      "width": "100%",
      "color": "darkGray.900",
      "margin-right": "20px",
      "margin-bottom": "20px",
      "as": "router-link",
      "to": {
        name: 'nutri.profile-gizi'
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "background-color": "primary.400",
      "px": "26px",
      "font-size": "18px",
      "font-weight": "500",
      "py": "26px",
      "border-radius": "58px",
      "width": "100%",
      "color": "white"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('submit');
      }
    }
  }, [_vm._v(" Simpan ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }