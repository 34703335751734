export function calculateBmi(height, weight) {
  // Berat Badan (kg) / [(Tinggi Badan (cm) / 100) ** 2]
  height = parseFloat(height)
  weight = parseFloat(weight)
  let result = weight / ((height / 100) ** 2)
  return parseFloat(result.toFixed(2))

  // height = Number(height ?? 0);
  // weight = Number(weight ?? 0);
  // return Number((weight / (height / 100) ** 2).toFixed(2));
}
