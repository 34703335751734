<template>
  <c-box
    w="100%"
    max-width="590px"
  >
    <c-form-control
      is-required
      :is-invalid="$v.fullName.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Nama Lengkap
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="fullName"
          type="text"
          placeholder="Masukkan Nama Lengkap"
          height="62px"
        />
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.fullName.required"
        color="red.500"
        font-size="12px"
      >
        Nama lengkap harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.age.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Umur (tahun)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          id="Age"
          v-model.number="age"
          type="number"
          placeholder="Masukkan Umur (tahun)"
          height="62px"
        />
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.age.required"
        color="red.500"
        font-size="12px"
      >
        Umur harus diisi
      </c-form-helper-text>
      <c-form-helper-text
        v-if="!$v.age.numeric"
        color="red.500"
        font-size="12px"
      >
        Umur harus berupa angka
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.gender.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Jenis Kelamin
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="gender"
          placeholder="Jenis Kelamin"
          height="62px"
        >
          <option value="male">
            Laki-laki
          </option>
          <option value="female">
            Perempuan
          </option>
        </c-select>
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.gender.required"
        color="red.500"
        font-size="12px"
      >
        Jenis Kelamin harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.physicalActivity.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        % Aktivitas Fisik
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="physicalActivity"
          placeholder="Pilih % Aktivitas Fisik"
          height="62px"
        >
          <option value="0">
            0%
          </option>
          <option value="10">
            10%
          </option>
          <option value="20">
            20%
          </option>
          <option value="30">
            30%
          </option>
          <option value="40">
            40%
          </option>
          <option value="50">
            50%
          </option>
          <option value="60">
            60%
          </option>
          <option value="70">
            70%
          </option>
          <option value="80">
            80%
          </option>
          <option value="90">
            90%
          </option>
        </c-select>
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.physicalActivity.required"
        color="red.500"
        font-size="12px"
      >
        Aktivitas fisik harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.weight.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Berat Badan (kg)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="weight"
          type="number"
          placeholder="Masukkan Berat Badan"
          height="62px"
          padding-right="60px"
        />
        <c-input-right-element
          width="4.5rem"
          mt="10px"
          margin-right="10px"
        >
          <c-text
            color="black.900"
            font-size="18px"
          >
            kg
          </c-text>
        </c-input-right-element>
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.weight.required"
        color="red.500"
        font-size="12px"
      >
        Berat badan harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.height.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Tinggi Badan (cm)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="height"
          type="number"
          placeholder="Masukkan Tinggi Badan"
          height="62px"
          padding-right="60px"
        />
        <c-input-right-element
          width="4.5rem"
          mt="10px"
          margin-right="10px"
        >
          <c-text
            color="black.900"
            font-size="18px"
          >
            cm
          </c-text>
        </c-input-right-element>
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.height.required"
        color="red.500"
        font-size="12px"
      >
        Tinggi badan harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.bodyMassIndex.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Indeks Massa Tubuh (IMT) (kg/m2)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="bodyMassIndex"
          type="number"
          placeholder="Masukkan Indeks Massa Tubuh (IMT) (kg/m2)"
          height="62px"
          padding-right="90px"
        />
        <c-input-right-element
          width="4.5rem"
          mt="10px"
          margin-right="30px"
        >
          <c-text
            color="black.900"
            font-size="18px"
          >
            (kg/m2)
          </c-text>
        </c-input-right-element>
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.bodyMassIndex.required"
        color="red.500"
        font-size="12px"
      >
        Indeks massa tubuh harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.nutritionStatus.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Status Gizi
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="nutritionStatus"
          placeholder="Masukkan Status Gizi"
          height="62px"
        >
          <option value="below_normal">
            Berat Badan Kurang
          </option>
          <option value="normal">
            Normal
          </option>
          <option value="pre_obesity">
            Preobesitas
          </option>
          <option value="obesity">
            Obesitas
          </option>
        </c-select>
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.nutritionStatus.required"
        color="red.500"
        font-size="12px"
      >
        Status gizi harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.idealWeightMinimum.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Minimum Berat Badan Ideal
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="idealWeightMinimum"
          type="number"
          placeholder="Masukkan Minimum Berat Badan Ideal"
          height="62px"
        />
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.idealWeightMinimum.required"
        color="red.500"
        font-size="12px"
      >
        Minimum berat badan ideal harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.idealWeightMaximum.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Maximum Berat Badan Ideal
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="idealWeightMaximum"
          type="number"
          placeholder="Masukkan Maximum Berat Badan Ideal"
          height="62px"
        />
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.idealWeightMaximum.required"
        color="red.500"
        font-size="12px"
      >
        Maximum berat badan ideal harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      v-if="gender === 'female'"
      is-required
      :is-invalid="$v.maternalCondition.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kondisi Keibuan
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="maternalCondition"
          placeholder="Kondisi Keibuan"
          height="62px"
        >
          <option value="0">
            Tidak ada / Hamil Trimester 1
          </option>
          <option value="340">
            Hamil Trimester 2
          </option>
          <option value="452">
            Hamil Trimester 3
          </option>
          <option value="330">
            Menyusui Bayi 0-6 Bulan
          </option>
          <option value="400">
            Menyusui Bayi 7-12 Bulan
          </option>
        </c-select>
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.maternalCondition.required"
        color="red.500"
        font-size="12px"
      >
        Kondisi keibuan harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control
      v-if="gender === 'female'"
      is-required
      :is-invalid="$v.extraMaternalCalories.$invalid"
      mb="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555"
        font-weight="400"
      >
        Tambahan kalori kondisi keibuan
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model="extraMaternalCalories"
          type="text"
          name="extraMaternalCalories"
          placeholder="Masukkan Tambahan kalori kondisi keibuan"
          height="62px"
        />
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.extraMaternalCalories.required"
        color="red.500"
        font-size="12px"
      >
        Tambahan kalori kondisi keibuan harus diisi
      </c-form-helper-text>
      <c-form-helper-text
        v-if="!$v.extraMaternalCalories.numeric"
        color="red.500"
        font-size="12px"
      >
        Tambahan kalori kondisi keibuan harus berupa angka
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.mealPlanGoal.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Tujuan Meal Plan
      </c-form-label>
      <textarea
        id="mealPlanGoal"
        v-model="mealPlanGoal"
        v-chakra
        rows="3"
        placeholder="Masukkan Tujuan Meal Plan"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
      />
      <c-form-helper-text
        v-if="!$v.mealPlanGoal.required"
        color="red.500"
        font-size="12px"
      >
        Tujuan Meal Plan harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control
      is-required
      :is-invalid="$v.maintainedThings.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Hal yang Perlu Dipertahankan
      </c-form-label>
      <textarea
        id="maintainedThings"
        v-model="maintainedThings"
        v-chakra
        rows="3"
        placeholder="Masukkan Hal yang Perlu Dipertahankan"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
      />
      <c-form-helper-text
        v-if="!$v.maintainedThings.required"
        color="red.500"
        font-size="12px"
      >
        Hal yang Perlu Dipertahankan harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control
      is-required
      :is-invalid="$v.fixedThings.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Hal yang Perlu Diperbaiki
      </c-form-label>
      <!-- <c-input-group size="md"> -->
      <textarea
        id="fixedThings"
        v-model="fixedThings"
        v-chakra
        rows="3"
        placeholder="Masukkan Hal yang Perlu Diperbaiki"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
      />
      <!-- </c-input-group> -->
      <c-form-helper-text
        v-if="!$v.fixedThings.required"
        color="red.500"
        font-size="12px"
      >
        Hal yang Perlu Diperbaiki harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control
      is-required
      :is-invalid="$v.basalEnergyNeeds.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Kebutuhan Energi Bassal (kkal)
      </c-form-label>
      <c-input-group size="md">
        <c-input
          v-model.number="basalEnergyNeeds"
          type="number"
          placeholder="Masukkan Kebutuhan Energi Bassal (kkal)"
          height="62px"
        />
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.basalEnergyNeeds.required"
        color="red.500"
        font-size="12px"
      >
        Kebutuhan Energi Bassal harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.calorieDeficit.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Defisit kalori yang diberikan
      </c-form-label>
      <c-input-group size="md">
        <c-select
          v-model="calorieDeficit"
          placeholder="Pilih Defisit kalori yang diberikan"
          height="62px"
        >
          <option value="250">
            250
          </option>
          <option value="350">
            350
          </option>
          <option value="500">
            500
          </option>
          <option value="650">
            650
          </option>
          <option value="750">
            750
          </option>
        </c-select>
      </c-input-group>
      <c-form-helper-text
        v-if="!$v.calorieDeficit.required"
        color="red.500"
        font-size="12px"
      >
        Defisit kalori yang diberikan harus diisi
      </c-form-helper-text>
    </c-form-control>

    <c-form-control
      is-required
      :is-invalid="$v.target.$invalid"
      margin-bottom="30px"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Target
      </c-form-label>
      <textarea
        id="target"
        v-model="target"
        v-chakra
        rows="3"
        placeholder="Masukkan Target"
        d="block"
        border="1px solid #E2E8F0"
        border-radius="md"
        minHeight="150px"
        type="text"
        lineHeight="20px"
        p="10px"
        w="100%"
      />
      <!-- </c-input-group> -->
      <c-form-helper-text
        v-if="!$v.target.required"
        color="red.500"
        font-size="12px"
      >
        Target harus diisi
      </c-form-helper-text>
    </c-form-control>
    <c-form-control
      is-required
      margin-bottom="30px"
      display="flex"
    >
      <c-button
        background-color="superLightGray.900"
        px="26px"
        font-size="18px"
        font-weight="500"
        py="26px"
        border-radius="58px"
        width="100%"
        color="darkGray.900"
        margin-right="20px"
        margin-bottom="20px"
        as="router-link"
        :to="{ name: 'nutri.profile-gizi' }"
      >
        Batal
      </c-button>
      <c-button
        background-color="primary.400"
        px="26px"
        font-size="18px"
        font-weight="500"
        py="26px"
        border-radius="58px"
        width="100%"
        color="white"
        @click="$emit('submit')"
      >
        Simpan
      </c-button>
    </c-form-control>
  </c-box>
</template>

<script>
import equal from 'fast-deep-equal'
import { calculateBmi } from '@/utils/calculate-bmi.js'
import { calculateStatusGizi } from '@/utils/calculate-status-gizi.js'
import { calculateKebutuhanEnergi } from '@/utils/calculate-kebutuhan-energi'
import {
  calculateBeratBadanIdealMaximum,
  calculateBeratBadanIdealMinimum,
} from '@/utils/calculate-berat-badan-ideal'
import { numeric, required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'EditProfileClientForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fullName: null,
      gender: null,
      bodyMassIndex: null,
      nutritionStatus: null,
      idealWeightMinimum: null,
      idealWeightMaximum: null,
      basalEnergyNeeds: null,
      age: null,
      physicalActivity: null,
      weight: null,
      height: null,
      maternalCondition: null,
      extraMaternalCalories: null,
      fixedThings: null,
      mealPlanGoal: null,
      maintainedThings: null,
      calorieDeficit: null,
      target: null,
    }
  },
  computed: {
    _bmi() {
      let height = Number(this.value?.height ?? 0)
      let weight = Number(this.value?.weight ?? 0)

      return calculateBmi(height, weight)
    },
    _statusGizi() {
      return calculateStatusGizi(this._bmi)
    },
    _minBeratBadan() {
      return calculateBeratBadanIdealMinimum(this.height)
    },
    _maxBeratBadan() {
      return calculateBeratBadanIdealMaximum(this.height)
    },
    _kebutuhanEnergi() {
      return calculateKebutuhanEnergi({
        gender: this.gender,
        beratBadan: this.weight,
        usia: this.age,
        tinggiBadan: this.height,
      })
    },
    _value() {
      return {
        fullName: this.fullName,
        age: this.age,
        weight: this.weight,
        height: this.height,
        gender: this.gender,
        bodyMassIndex: this.bodyMassIndex,
        nutritionStatus: this.nutritionStatus,
        idealWeightMinimum: this.idealWeightMinimum,
        idealWeightMaximum: this.idealWeightMaximum,
        basalEnergyNeeds: this.basalEnergyNeeds,
        physicalActivity: this.physicalActivity,
        maternalCondition: (this.maternalCondition ?? '0'),
        extraMaternalCalories: +(this.extraMaternalCalories ?? 0),
        fixedThings: this.fixedThings,
        mealPlanGoal: this.mealPlanGoal,
        maintainedThings: this.maintainedThings,
        calorieDeficit: +this.calorieDeficit,
        target: this.target,
      }
    },
  },
  watch: {
    _kebutuhanEnergi(v1, v2) {
      if (equal(v1, v2)) return
      this.basalEnergyNeeds = v1
    },
    _minBeratBadan(v1, v2) {
      if (equal(v1, v2)) return
      this.idealWeightMinimum = v1
    },
    _maxBeratBadan(v1, v2) {
      if (equal(v1, v2)) return
      this.idealWeightMaximum = v1
    },
    _statusGizi(v1, v2) {
      if (equal(v1, v2)) return
      this.nutritionStatus = v1
    },
    _bmi(v1, v2) {
      if (equal(v1, v2)) return
      this.bodyMassIndex = v1
    },
    _value: {
      deep: true,
      handler(v) {
        this.$emit('input', v)
      },
    },
    value: {
      immediate: true,
      handler(val) {
        if (val == null) return

        this.fullName = val.fullName
        this.age = val.age
        this.gender = val.gender
        this.weight = val.weight
        this.height = val.height
        this.bodyMassIndex = val.bodyMassIndex
        this.nutritionStatus = val.nutritionStatus
        this.idealWeightMaximum = val.idealWeightMaximum
        this.idealWeightMinimum = val.idealWeightMinimum
        this.basalEnergyNeeds = val.basalEnergyNeeds
        this.calorieDeficit = val.calorieDeficit?.toString()
        this.target = val.target
        this.mealPlanGoal = val.mealPlanGoal
        this.maintainedThings = val.maintainedThings
        this.physicalActivity = val.physicalActivity
        this.fixedThings = val.fixedThings
        this.maternalCondition = val.maternalCondition?.toString()
        this.extraMaternalCalories = val.extraMaternalCalories
      },
    },
  },
  validations: {
    fullName: { required },
    age: { required, numeric },
    weight: { required, numeric },
    height: { required, numeric },
    gender: { required },
    bodyMassIndex: { required },
    nutritionStatus: { required },
    idealWeightMinimum: { required },
    idealWeightMaximum: { required },
    basalEnergyNeeds: { required },
    physicalActivity: { required },
    maternalCondition: { required: requiredIf(v => v.gender === 'female') },
    extraMaternalCalories: { required: requiredIf(v => v.maternalCondition !== '0'), numeric },
    mealPlanGoal: { required },
    maintainedThings: { required },
    fixedThings: { required },
    calorieDeficit: { required },
    target: { required },
  },
}
</script>
