var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "overflow": "auto",
      "pb": "30px"
    }
  }, [_c('c-box', {
    attrs: {
      "px": "40px"
    }
  }, [_c('c-heading', {
    attrs: {
      "color": "black.900",
      "font-size": "24px",
      "font-weight": "700",
      "margin-bottom": "40px"
    }
  }, [_vm._v(" Profile Klien ")]), _vm.profile != null ? _c('EditProfileClientForm', {
    on: {
      "submit": _vm.submit
    },
    model: {
      value: _vm.profile,
      callback: function callback($$v) {
        _vm.profile = $$v;
      },
      expression: "profile"
    }
  }) : _vm._e()], 1), _vm.isPopupOpen ? _c('popup-container', {
    attrs: {
      "max-w": 600
    },
    on: {
      "close": function close($event) {
        return _vm.closePopup();
      }
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "max-w": "600px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "align-items": "center",
      "justify-content": "center",
      "mt": "60px",
      "px": "20px",
      "pb": "20px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('./img-meal-plan-intro.svg'),
      "alt": "image intro illustration",
      "w": "200px"
    }
  }), _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "16px",
      "text-align": "center",
      "color": "#000000",
      "mt": "10px"
    }
  }, [_vm._v(" Yeay! Profile gizi klien berhasil disubmit ")])], 1), _c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "border-radius": "24px",
      "h": "50px",
      "w": "122px",
      "m": "20px",
      "mb": "0",
      "align-self": "flex-end"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.closePopup();
      }
    }
  }, [_vm._v(" Mengerti ")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }